import { BooleanYesNo } from '@/constants/enums';
import { SORTING_ORDER } from '@/constants/ui';
import { DaysOfWeekAbbreviationFromFullString } from '@/types/ui.types';

// Start View Enums

export interface PublicViewDTO {
  customer_id: number;
  id: string;
  name: string;
  view_id: number;
}

export interface PublicView {
  customerId: number;
  id: string;
  name: string;
  viewId: number;
}

export enum ViewLayoutType {
  STANDARD = 'hybrid',
  COLUMNS = 'grid',
  CALENDAR = 'calendar',
  GANTT = 'gantt',
  LIST = 'list',
  BLOCK = 'block',
}

export enum ViewLayoutGroupBy {
  ASSIGNMENT = 'assignment',
  PERSONNEL = 'personnel',
}

export enum ViewLayoutDataType {
  REQUEST = 'request',
  SCHEDULE = 'schedule',
  BOTH = 'combined',
}

export enum ViewLayoutDisplayRange {
  DAY = 'day',
  MONTH = 'month',
  WEEK = 'week',
}

export enum ViewLayoutListColumns {
  ASSIGNMENT = 'Assignment',
  ASSIGNMENT_INFORMATION = 'Assignment Information',
  ASSIGNMENT_TYPE = 'Assignment Type',
  ASSIGNMENT_UNITS = 'Assignment Units',
  BUSINESS_PHONE = 'Business Phone',
  CALL_ORDER = 'Call Order',
  CATEGORY = 'Category',
  CELL_PHONE = 'Cell Phone',
  CUSTOM_1 = 'Custom1',
  CUSTOM_2 = 'Custom2',
  CUSTOM_3 = 'Custom3',
  CUSTOM_4 = 'Custom4',
  DATE = 'Date',
  DEPARTMENT = 'Department',
  EMAIL = 'Email',
  HOME_PHONE = 'Home Phone',
  LOCATIONS = 'Location(s)',
  MESSAGE = 'Message',
  NOTE = 'Note',
  PAGER = 'Pager',
  PERSONNEL = 'Personnel',
  PERSONNEL_TYPE = 'Personnel Type',
  SENIORITY_DATE = 'Seniority Date',
  START_TIME = 'Start Time',
  STOP_TIME = 'Stop Time',
  TEMPLATE = 'Template',
  TITLE = 'Title',
  TOTAL_HOURS = 'Total Hours',
  UNIQUE_ID = 'UniqueID',
  WEEKLY_HOURS = 'Weekly Hours',
}

export enum ViewLayoutBlockRange {
  ANCHORED = 'ANCHORED',
  STATIC = 'STATIC',
}

export enum ViewEditorDraftSortByAssignmentOptions {
  CUSTOM = 'custom order',
  NAME = 'name',
  TIME_THEN_CUSTOM = 'time then custom order',
  TIME_THEN_NAME = 'time then name',
}

export enum ViewEditorDraftSortByPersonnelOptions {
  CUSTOM = 'custom order',
  DISPLAY_NAME = 'display name',
  LAST_NAME = 'last name',
  REQUEST_TIME_SUBMITTED = 'request time submitted',
  TIME_THEN_CUSTOM = 'time then custom order',
  TIME_THEN_DISPLAY_NAME = 'time then display name',
  TIME_THEN_LAST_NAME = 'time then last name',
}

export enum ViewEditorDisplayDepartmentNames {
  DEPARTMENT_COMPACT_NAME = 'department compact name',
  DEPARTMENT_NAME = 'department name',
  NONE = 'Not Displayed',
}

export enum GridSettingsLeftColumnType {
  ASSIGNMENT = 'assignment',
  PERSONNEL = 'personnel',
}

// End View Enums

// Start View Types

export type StartOnDayOfWeek =
  | DaysOfWeekAbbreviationFromFullString.Monday
  | DaysOfWeekAbbreviationFromFullString.Saturday
  | DaysOfWeekAbbreviationFromFullString.Sunday;
// eslint-disable-next-line no-magic-numbers
export type ViewLayoutBlockWeekLength = '1' | '2' | '3' | '4';
// eslint-disable-next-line no-magic-numbers
export type ViewLayoutBlockLengthDays = '1' | '2' | '3' | '4' | '5' | '6' | '7';
type ViewLayoutDisplayRangeOmitDay = Omit<ViewLayoutDisplayRange, ViewLayoutDisplayRange.DAY>;
type ViewLayout =
  | ViewLayoutBlock
  | ViewLayoutCalendar
  | ViewLayoutColumns
  | ViewLayoutGantt
  | ViewLayoutList
  | ViewLayoutStandard;
export type ViewLayoutViewerOptions =
  | ViewLayoutBlockViewerOptions
  | ViewLayoutCalendarViewerOptions
  | ViewLayoutColumnsViewerOptions
  | ViewLayoutGanttViewerOptions
  | ViewLayoutListViewerOptions
  | ViewLayoutStandardViewerOptions;

// End View Types

// Start View Interfaces

export interface ViewColoredItem {
  color: string | null;
  colorText: string | null;
  id: number | string;
}

export interface ViewFilter {
  filter_id: number;
  on_assignments: ViewColoredItem[];
  on_departments: number[];
  on_demandTallies: ViewColoredItem[];
  on_personnel: ViewColoredItem[];
  on_tallies: ViewColoredItem[];
  on_templates: number[];
  sort_assignments_by: ViewEditorDraftSortByAssignmentOptions;
  sort_personnel_by: ViewEditorDraftSortByPersonnelOptions;
  assignment_expression?: string | null;
  auto_add_assignments?: boolean;
  auto_add_personnel?: boolean;
  created_at?: string;
  created_by?: string;
  hide_inactive_personnel?: boolean;
  hide_inactive_assignments?: boolean;
  id?: number;
  name?: string;
  personnel_expression?: string | null;
  updated_at?: string;
}

export type ViewFilterDTO = Omit<
  ViewFilter,
  'on_demandTallies' | 'filter_id' | 'name' | 'created_by' | 'created_at' | 'updated_at'
>;

export type ViewThemeStartOnDay = 'Sun' | 'Mon' | 'Sat';

export const ViewThemeStartOnDayMap = {
  Mon: 'Monday',
  Sat: 'Saturday',
  Sun: 'Sunday',
};

export interface MultiSortColumn {
  columnValue: ViewLayoutListColumns;
  id: number;
  sortDirection: SORTING_ORDER;
}

// ToDo: Add enum for these
export interface ViewThemeData {
  layout: ViewLayoutType;
  blockAnchorDate?: string;
  blockLength?: ViewLayoutBlockLengthDays;
  blockStart?: DaysOfWeekAbbreviationFromFullString;
  blockStaticStart?: string;
  blockStaticStop?: string;
  blockViewTotalWeeks?: number;
  blockWeekLength?: ViewLayoutBlockWeekLength;
  colorMethod?: string;
  colorTextMethod?: string;
  compactMode?: BooleanYesNo;
  condenseColumns?: BooleanYesNo;
  dataType?: ViewLayoutDataType;
  displayDepartmentNames?: ViewEditorDisplayDepartmentNames;
  GridSettings_leftColumnType?: GridSettingsLeftColumnType;
  hideBlankRows?: BooleanYesNo;
  hidePending?: BooleanYesNo;
  hideWeekends?: BooleanYesNo;
  listColumns?: ViewLayoutListColumns[];
  multiSortColumns: MultiSortColumn[];
  personalFilter?: BooleanYesNo;
  range?: ViewLayoutDisplayRange;
  shouldCountTalliesByPersonnelFilter?: BooleanYesNo;
  showLoggedInOnly?: BooleanYesNo;
  showTimes?: BooleanYesNo;
  startOnDay?: ViewThemeStartOnDay;
}

export interface ViewTheme {
  data: ViewThemeData;
  name: string;
  theme_id: number;
  created_by?: string;
  created_at?: string;
  updated_at?: string;
}

interface View {
  accessibleBy: number[];
  filter: ViewFilter;
  filterId: number;
  name: string;
  personnel: number[];
  publicViewId: string | null;
  templates: number[];
  theme: ViewTheme;
  themeId: number;
  updatedBy: string;
  updatedTimestamp: string;
  viewId: number;
  updatedTimestampCompact?: string;
  createdAt?: string;
  createdBy?: string;
  departments?: number[] | { id: number; name: string }[];
  updatedAt?: string;
}

export interface ViewLite {
  departments: number[];
  id: number;
  name: string;
  templates: number[];
}

export interface ViewLiteDTO {
  view_id: number;
  name: string;
  filter: ViewFilter;
  read_only: boolean;
}

export interface UpdateFilterDTO {
  autoAddAssignments: boolean;
  autoAddPersonnel: boolean;
  filterId: number;
  name: string;
  onAssignments: ViewColoredItem[];
  onDepartments: number[];
  onPersonnel: ViewColoredItem[];
  onTallies: ViewColoredItem[];
  sortAssignmentsBy: ViewEditorDraftSortByAssignmentOptions;
  sortPersonnelBy: ViewEditorDraftSortByPersonnelOptions;
}

export interface UpdateThemeDTO {
  data: Partial<ViewThemeData>;
  name: string;
  themeId: number;
}

export interface ViewListItem {
  departments: number[];
  id: number;
  name: string;
  publicViewId: string | null;
  templates: number[];
  updatedBy: string;
  updatedTimestamp: string;
  updatedTimestampCompact?: string;
}

// End View Interfaces

// Start View Editor Draft Interfaces

export interface ViewEditorDraftColoredItem {
  cellColor: string | null;
  displayName: string;
  id: number;
  textColor: string | null;
}

// Start View Layout Interfaces
export interface ViewLayoutBlockSharedProperties {
  // Length of block in days
  blockLength: ViewLayoutBlockLengthDays;
  blockStart: DaysOfWeekAbbreviationFromFullString;
  blockWeekLength: ViewLayoutBlockWeekLength;
}

export interface ViewLayoutBlockRangeAnchored extends ViewLayoutBlockSharedProperties {
  blockAnchorDate: string;
  blockViewTotalWeeks: number;
}

export interface ViewLayoutBlockRangeStatic extends ViewLayoutBlockSharedProperties {
  blockStaticStart: string;
  blockStaticStop: string;
}

type ViewLayoutBlockMutexProperties<T, U, V> =
  | (T extends U ? (T extends V ? never : T) : never)
  | (V extends U ? (V extends U ? never : V) : never);

export type ViewLayoutBlock = ViewLayoutBlockMutexProperties<
  ViewLayoutBlockRangeAnchored,
  ViewLayoutBlockSharedProperties,
  ViewLayoutBlockRangeStatic
>;

export type ViewLayoutBlockInclusive = ViewLayoutBlockRangeAnchored & ViewLayoutBlockRangeStatic;

export interface ViewLayoutCalendar {
  dataType: ViewLayoutDataType;
  displayRange: ViewLayoutDisplayRange.MONTH;
  groupBy: ViewLayoutGroupBy;
  layout: ViewLayoutType.CALENDAR;
}

export interface ViewLayoutColumns {
  dataType: ViewLayoutDataType;
  displayRange: ViewLayoutDisplayRange.MONTH;
  groupBy: ViewLayoutGroupBy;
  layout: ViewLayoutType.COLUMNS;
}

export interface ViewLayoutGantt {
  dataType: ViewLayoutDataType;
  displayRange: ViewLayoutDisplayRange.DAY;
  groupBy: ViewLayoutGroupBy;
  layout: ViewLayoutType.GANTT;
}

export interface ViewLayoutList {
  columns: ViewLayoutListColumns[];
  dataType: ViewLayoutDataType;
  displayRange: ViewLayoutDisplayRangeOmitDay;
  groupBy: ViewLayoutGroupBy;
  layout: ViewLayoutType.LIST;
}

export interface ViewLayoutStandard {
  dataType: ViewLayoutDataType;
  displayRange: ViewLayoutDisplayRangeOmitDay;
  groupBy: ViewLayoutGroupBy;
  layout: ViewLayoutType.STANDARD;
}

// End View Layout Interfaces

// Start View Layout Viewer Options Interfaces

export interface ViewLayoutBlockViewerOptions {
  compactMode: boolean;
  hideBlankRows: boolean;
  showLoggedInUserOnly: boolean;
}

export interface ViewLayoutCalendarViewerOptions {
  compactMode: boolean;
  displayDepartmentNames: ViewEditorDisplayDepartmentNames;
  enableSavedFilters: boolean;
  hideWeekends: boolean;
  showLoggedInUserOnly: boolean;
  showTimes: boolean;
  startOnDayOfWeek: StartOnDayOfWeek;
}

export interface ViewLayoutColumnsViewerOptions {
  compactMode: boolean;
  condenseColumns: boolean;
  displayDepartmentNames: ViewEditorDisplayDepartmentNames;
  enableSavedFilters: boolean;
  hideBlankRows: boolean;
  hideWeekends: boolean;
  shouldCountTalliesByPersonnelFilter: boolean;
  showLoggedInUserOnly: boolean;
  showTimes: boolean;
}

export interface ViewLayoutGanttViewerOptions {
  compactMode: boolean;
  showLoggedInUserOnly: boolean;
}

export interface ViewLayoutListViewerOptions {
  compactMode: boolean;
  hidePendingSlots: boolean;
  showLoggedInUserOnly: boolean;
}

export interface ViewLayoutStandardViewerOptions {
  compactMode: boolean;
  displayDepartmentNames: ViewEditorDisplayDepartmentNames;
  enableSavedFilters: boolean;
  hideBlankRows: boolean;
  hideWeekends: boolean;
  shouldCountTalliesByPersonnelFilter: boolean;
  showLoggedInUserOnly: boolean;
  showTimes: boolean;
  startOnDayOfWeek: StartOnDayOfWeek;
}

// End View Layout Viewer Options Interfaces

export interface ViewEditorUIOptions {
  hideInactiveAssignments: boolean;
  hideInactivePersonnel: boolean;
}

export interface ViewEditorDraft {
  assignments: ViewEditorDraftColoredItem[];
  departmentIds: number[];
  layout: ViewLayout;
  name: string;
  personnel: ViewEditorDraftColoredItem[];
  sortByAssignment: ViewEditorDraftSortByAssignmentOptions;
  sortByPersonnel: ViewEditorDraftSortByPersonnelOptions;
  tallyIds: number[];
  templateIds: number[];
  uiOptions: ViewEditorUIOptions;
  viewerOptions: ViewLayoutViewerOptions;
}

// End View Editor Draft Interfaces

export default View;
